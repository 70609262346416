import { QueryWorkflowItemDTO, WorkflowDTO } from '@/api/dto/workflow.dto';
import {
  deleteMultipleWorkflows,
  fetchWorkflowList,
} from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';
import { SortDirection } from '@/helpers/data-query';
import { FetchStates } from '@/helpers/fetch-states';
import { OrganizationsModule } from '@/store/organizations';
import { ElMessage } from 'element-plus';
import { computed, ref, watch } from 'vue';

export function useWorkflowList() {
  const ORG_KEY = 'org';
  const EVENT_KEY = 'event';
  const TITLE_KEY = 'title';
  const DATE_CREATED_KEY = 'date_created';
  const DATE_MODIFIED_KEY = 'date_modified';

  const workflows = ref<QueryWorkflowItemDTO[]>([]);
  const selected = ref<string[]>([]);
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const returnCount = ref(0);
  const total = ref(0);
  const page = ref(1);
  const pageSize = ref(100);
  const sortDir = ref<SortDirection>(SortDirection.ASC);
  const sortBy = ref<keyof QueryWorkflowItemDTO>('title');
  const orgIds = computed(() => OrganizationsModule.activeOrgIds);

  const fetchWorkflows = async () => {
    if (orgIds.value.length < 1) {
      workflows.value = [];
      returnCount.value = 0;
      total.value = 0;
      page.value = 1;

      return;
    }

    fetchState.value = FetchStates.FETCHING;
    try {
      const fetchedWorkflows = await fetchWorkflowList(orgIds.value);

      workflows.value = fetchedWorkflows;
      // returnCount.value = data.return_count;
      total.value = fetchedWorkflows.length;

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      alertError(error, 'There was an error fetching workflows.');
      console.error(error);
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  const deleteWorkflows = async () => {
    try {
      fetchState.value = FetchStates.FETCHING;

      const numDeleted = await deleteMultipleWorkflows(selected.value);

      ElMessage.success(`Successfully deleted ${numDeleted} workflows.`);

      await fetchWorkflows();

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      alertError(error, 'There was an error deleting the selected workflows.');
      console.error(error);
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  const onSortChanged = ({
    order,
    prop,
  }: {
    order: 'ascending' | 'descending';
    prop: keyof QueryWorkflowItemDTO;
    column: any;
  }) => {
    page.value = 1;
    sortBy.value = prop;

    if (order === 'descending') sortDir.value = SortDirection.DESC;
    else sortDir.value = SortDirection.ASC;
  };

  const onSelectChanged = (val: WorkflowDTO[]) =>
    (selected.value = val.map(({ _id }) => _id));

  watch(page, fetchWorkflows);
  watch(pageSize, fetchWorkflows);
  watch(sortBy, fetchWorkflows);
  watch(sortDir, fetchWorkflows);
  watch(orgIds, fetchWorkflows);

  fetchWorkflows();

  return {
    workflows,
    selected,
    fetchState,
    returnCount,
    total,
    page,
    pageSize,
    sortDir,
    sortBy,
    orgIds,
    fetchWorkflows,
    deleteWorkflows,
    onSortChanged,
    onSelectChanged,
    ORG_KEY,
    EVENT_KEY,
    TITLE_KEY,
    DATE_CREATED_KEY,
    DATE_MODIFIED_KEY,
  };
}
