
import { defineComponent } from 'vue';
import WorkflowList from '@/components/workflows/WorkflowList.vue';

export default defineComponent({
  name: 'WorkflowListPage',
  components: {
    WorkflowList,
  },
});
