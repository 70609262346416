
import { defineComponent, onMounted, Ref, ref } from 'vue';
import { useWorkflowList } from '@/composables/useWorkflowList';
import { FetchStates } from '@/helpers/fetch-states';
import { useOrgManager } from '@/composables/useOrgManager';
import { useAuth } from '@/composables/useAuth';
import router from '@/router';
import { convertDateString } from '@/helpers/date-format';
import { UserPrefsModule } from '@/store/userprefs';
import { UserPrefs } from '@/api/dto/user-prefs';

export default defineComponent({
  setup() {
    const { orgMap } = useOrgManager();
    const { isVAR, isSuperAdmin } = useAuth();

    const routeToCreateWorkflow = () =>
      router.push({ name: 'WorkflowEditPage', params: { id: 'create' } });

    const userPrefs: Ref<UserPrefs | null> = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });

    return {
      ...useWorkflowList(),
      orgMap,
      isVAR,
      isSuperAdmin,
      routeToCreateWorkflow,
      FetchStates,
      convertDateString,
      Date,
      userPrefs,
    };
  },
});
